import { get, isNil } from "lodash";

import JsonURL from "@jsonurl/jsonurl";
import { Room } from "./room-picker";

export const getNumberFromParams = (params: URLSearchParams, name: string): number | undefined => {
  const numberString = params.get(name);

  if (numberString === null) {
    return undefined;
  }

  return parseInt(numberString);
};

export const getNumbersFromParams = (
  params: URLSearchParams,
  name: string
): number[] | undefined => {
  const numbersString = params.get(name);

  if (numbersString === null) {
    return undefined;
  }

  return numbersString.split(",").map((x) => parseInt(x.trim()));
};

export const getRoomsFromParams = (params: URLSearchParams, name: string): Room[] | undefined => {
  const roomsString = params.get(name);

  if (roomsString === null) {
    return undefined;
  }

  // Encode the accommodation code in the room string because (external) acco codes can contain special characters like '='. eg: 'ACNYNEgUzMDc4NBoKMTAzODcwMjk4NQ=='
  const safeRoomsString = roomsString.replace(/accommodationCode:([^,]*)/, (match, code) => {
    return `accommodationCode:${encodeURIComponent(code)}`;
  });

  const rooms = JsonURL.parse(safeRoomsString) as unknown | undefined;
  if (isNil(rooms) || !Array.isArray(rooms)) {
    return undefined;
  }

  return rooms.map((room) => {
    const adults = get(room, "adults", 0);
    const childAges = get(room, "childAges", []);
    return {
      adults,
      childAges: Array.isArray(childAges) ? childAges : [],
      children: Array.isArray(childAges) ? childAges.length : 0,
    } as Room;
  });
};

export const getDateFromParams = (params: URLSearchParams, name: string): Date | undefined => {
  const dateString = params.get(name);

  if (dateString === null) {
    return undefined;
  }

  const year = parseInt(dateString.slice(0, 4));
  const month = parseInt(dateString.slice(4, 6));
  const date = parseInt(dateString.slice(6, 8));

  return new Date(Date.UTC(year, month - 1, date));
};

export function sortProps<T>(unsorted: T) {
  return (Object.keys(unsorted) as (keyof T)[]).sort().reduce<Partial<T>>((sorted, key) => {
    sorted[key] = unsorted[key];
    return sorted;
  }, {}) as T;
}
